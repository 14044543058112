<template>
  <div class="main">
    <heads></heads>
    <banners></banners>
    <!-- <islogin /> -->
    <div class="content">
      <div class="cons">
        <div class="sctp-title">智能排版</div>
        <shuoming :smdata="smdata" :srcList="srcList"></shuoming>
        <div class="sctp">
          <el-upload
            drag
            v-model:file-list="fileList"
            class="upload-demo"
            action="https://titletools-api.yuanpingjia.cn/wordFormat/upload"
            :headers="headersObj"
            list-type="text"
            :limit="1"
            accept=".doc, .docx,"
            :on-exceed="handleExceed"
            :on-success="uploadsuccess"
            :before-upload="handlePreview"
            :before-remove="handleRemove"
          >
            <el-icon class="el-icon--upload"><upload-filled /></el-icon>
            <div class="el-upload__text">
              拖拽文档到此处或 <em>点击此处</em> 选择文档上传
            </div>
            <template #tip>
              <div class="el-upload__tip">只能上传doc或docx格式文档</div>
            </template>
          </el-upload>
        </div>
        <div class="sctp-info">
          <el-button type="info" @click="clickcklb">查看已上传文档</el-button>
        </div>
      </div>
    </div>
    <footers></footers>
  </div>
</template>

<script setup>
import { post } from "@/utils/index";
import { ElMessage } from "element-plus";
import { reactive, ref } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();
// 图片
let smdata = reactive([
  { url: "/img/shuoming/09-01.png", tips: "上传文件" },
  { url: "/img/shuoming/09-02.png", tips: "开始排版" },
  { url: "/img/shuoming/09-02.png", tips: "下载文件" },
]);
let srcList = reactive([
  "/img/shuoming/09-01.png",
  "/img/shuoming/09-02.png",
  "/img/shuoming/09-03.png",
]);
// headers
let headersObj = reactive({
  Authorization: "Bearer " + window.localStorage.getItem("token"),
});
// 文件列表
let fileList = ref([]);
// 上传之前
const handlePreview = (file) => {
  // 此处可以限制大小
  // ElMessage({
  //   message: "图片超出大小",
  //   duration: 1000,
  //   type: 'warning',
  // });
  // return false
};
// 上传成功后
const uploadsuccess = (file) => {
  if (file.code == 200) {
    ElMessage({
      message: "上传成功",
      duration: 1000,
      onClose: () => {
        router.push({
          path: "/znpb/list",
          query: {},
        });
      },
    });
  } else if(file.code == 401){
    ElMessage({
      message: file.message,
      type: "error",
      duration:1000,
      onClose:()=>{
        router.push({
          path: "/login",
          query: {},
        });
      }
    });
  }else {
    ElMessage({
      message: "上传失败",
      duration: 1000,
      type: "error",
    });
  }
};
// 超出限制
const handleExceed = (file) => {
  ElMessage({
    message: "只能上传一个文档",
    duration: 1000,
    type: "warning",
  });
};
// 删除文件
const handleRemove = (uploadFile, uploadFiles) => {
  // pictureUrl.value = "";
};

// 返回列表
const clickcklb = () => {
  router.push({
    path: "/znpb/list",
    query: {},
  });
};
</script>

<style scoped>
.main {
  background: #fff;
}
.content {
  width: 1200px;
  height: auto;
  margin: auto;
}
.cons {
  width: 1200px;
  margin: auto;
  padding: 30px 0;
}
.sctp-info {
  margin-top: 20px;
}
.sctp-title {
  text-align: center;
  font-size: 24px;
  padding-bottom: 20px;
}
</style>
